// Step 1: Import your component
import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { StaticImage } from "gatsby-plugin-image";
import LayoutFlex from "../components/LayoutFLex";
import Figure from "../components/Figure";
import Container from "../components/Container"


const pageStyles = {
  color: "#232129",
  fontFamily: "Open Sans Condensed",
};

const TherapyPage = () => {
  return (
    <main style={pageStyles}>
      <title>Über mich</title>
      <Header />

      <LayoutFlex style={{
            padding: "2em",
            width: "50%",
          }}>
        <div className="headdings">
          <h1>Über mich</h1>
        </div>
        <StaticImage 
        alt="Kunst therapie"
        src={"../images/silvija-main.jpeg"}
         placeholder="blurred"
         layout="fixed"
         width={700}
         height={400}
        />
      </LayoutFlex>
      <Figure>
        <blockquote>
        <cite>„Was hat mich bewegt den Weg der Kunsttherapie zu gehen?
Leben, statt gelebt zu werden!“</cite>
       </blockquote>
        <figcaption>Silvija Podgorac</figcaption>
      </Figure>



        

        
      
      <Container>
        <h3>Über mich:</h3>
        <div>
          <p>
          Nach jahrelanger Tätigkeit in einem Großunternehmen kam der Wunsch nach einer
neuen beruflichen Veränderung, wo ich mich beruflich sowie persönlich weiterentfalten
konnte.
          </p>
          <p>
          Inspiriert durch meinen kreativen Prozess wuchs mein Interesse für Psychologie.
Ich begann mit einer vierjährigen Ausbildung an der Wiener Schule für
Kunsttherapie die ich mit dem Diplom als Kunsttherapeutin mit klinischer Erfahrung
abschloss. Weiters machte ich die Ausbildung zur Dipl. Lebens- und Sozialberaterin
bei ATi-Ansorge. Während meines Praktikums im Pflegezentrum
Maria Lanzendorf, als auch mit Kindergruppen und Einzelberatungen, konnte ich viele
Erfahrungen sammeln.
          </p>
      </div>      
      </Container>
      <Footer />
    </main>
  );
};

// Step 3: Export your component
export default TherapyPage;
